import type { NavigationGuardNext, RouteLocationNormalized, RouteRecordRaw } from 'vue-router'
import { useToast } from 'vue-toastification'
import type { AxiosError } from 'axios'
import { AuctionStatusEnum, ConsentTypesEnum } from '@apiTypes'
import i18n from '@/plugins/i18n'
import { useAuctionsStore } from '@/stores/auction'
import { useAuthStore } from '@/stores/auth'
import { useCarriersStore } from '@store/carrier'
import { clearAuthData, isUserLoggedIn, refreshTokenHelper, switchWorkspaceHelper } from '@/auth/utils'
import { publicPages } from '@/router/public-pages'
import { useTransportsStore } from '@store/transport'
import { usePrivacyStore } from '@store/privacy'

type RouteGuard = (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => RouteGuardResponse
type RouteGuardResponse = Promise<any>

export const routeGuardMap: Record<string, RouteGuard> = {
  'request-edit-id': auctionEditGuard,
  'request-copy-id': auctionCopyGuard,
  'request-view-id': auctionViewGuard,
  'request-offer-id': auctionOfferGuard,
  'transport-view-id': transportViewGuard,
  'transport-edit-id': transportEditGuard,
  'carrier-chash-auction-hash': carrierAuctionGuard,
  'privacy-preferences-hash': carrierConsentsGuard,
  'login': loggedUserRedirectGuard,
  'register': loggedUserRedirectGuard,
  'index': landingDataGuard,
}

export function extendRouteWithGuard(route: RouteRecordRaw): RouteRecordRaw {
  const routeName = route.children?.[0]?.name?.toString() || ''

  const guards: RouteGuard[] = []

  if (!publicPages.includes(routeName))
    guards.push(dataGuard)

  if (routeGuardMap[routeName])
    guards.push(routeGuardMap[routeName])

  return { ...route, beforeEnter: guards }
}

async function dataGuard(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): RouteGuardResponse {
  if (isUserLoggedIn()) {
    try {
      await fetchData()
      next()
    }
    catch (e: unknown) {
      await checkGuardDataError(e as AxiosError, next, undefined, 'login')
    }
  }
  else {
    next({ name: 'login' })
  }
}

async function landingDataGuard(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): RouteGuardResponse {
  if (isUserLoggedIn()) {
    try {
      await fetchData()
      next()
    }
    catch (e: unknown) {
      await checkGuardDataError(e as AxiosError, next)
    }
  }
  else {
    next()
  }
}

async function auctionEditGuard(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): RouteGuardResponse {
  await auctionStatusGuard(to, from, next, status => status === AuctionStatusEnum.DRAFT, i18n.global.t('Could not access edit page'))
}

async function auctionCopyGuard(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): RouteGuardResponse {
  await auctionStatusGuard(to, from, next, () => true, i18n.global.t('Could not access edit page'))
}

async function auctionViewGuard(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): RouteGuardResponse {
  await auctionStatusGuard(to, from, next, status => status !== AuctionStatusEnum.DRAFT, i18n.global.t('Could not access detail page'))
}

async function auctionOfferGuard(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): RouteGuardResponse {
  const toast = useToast()
  const auctionStore = useAuctionsStore()
  if (auctionStore.instantOrderRequest && auctionStore.instantOrderResponse) {
    await auctionStatusGuard(to, from, next, status => status === AuctionStatusEnum.ACTIVE || status === AuctionStatusEnum.BIDDING_FINISHED, i18n.global.t('Could not access offer page'))
  }
  else {
    const id = +to.params.id

    next({ name: 'request-view-id', params: { id } })
    toast.error(i18n.global.t('InstaOrder expired'))
  }
}

async function transportViewGuard(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): RouteGuardResponse {
  await transportGuard(to, from, next, i18n.global.t('Could not access detail page'))
}

async function transportEditGuard(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): RouteGuardResponse {
  await transportGuard(to, from, next, i18n.global.t('Could not access edit page'))
}

async function transportGuard(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext, errorMessage: string): RouteGuardResponse {
  const id = +to.params.id
  const transportsStore = useTransportsStore()
  const toast = useToast()

  try {
    const response = await transportsStore.fetchTransport(id)
    if (response.id) {
      to.meta.transport = response
      next()
    }
    else {
      next({ name: 'transport-list' })
      toast.error(errorMessage)
    }
  }
  catch (e: unknown) {
    next({ name: 'transport-list' })
    toast.error(i18n.global.t('Transport does not exist in this workspace, or you do not have access to it'))
  }
}

async function auctionStatusGuard(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext, stateFn: (state: AuctionStatusEnum) => boolean, errorMessage: string): RouteGuardResponse {
  const id = +to.params.id
  const auctionsStore = useAuctionsStore()
  const toast = useToast()

  try {
    const response = await auctionsStore.fetchAuction(id)
    if (stateFn(response.status)) {
      to.meta.auction = response
      next()
    }
    else {
      next({ name: 'request-list' })
      toast.error(errorMessage)
    }
  }
  catch (e: unknown) {
    next({ name: 'request-list' })
    toast.error(i18n.global.t('Request does not exist in this workspace, or you do not have access to it'))
  }
}

export async function carrierAuctionGuard(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): RouteGuardResponse {
  const carrierHash = to.params.chash as string
  const auctionHash = to.params.hash as string

  const auctionsStore = useAuctionsStore()

  try {
    await fetchDataForCarrierByHash(carrierHash)
    to.meta.auction = await auctionsStore.fetchCarrierAuction(auctionHash, carrierHash)
    next()
  }
  catch (e: unknown) {
    next({ name: 'all' })
    console.error('Failed to fetch carrier auction:', e)
  }
}

export async function carrierConsentsGuard(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): RouteGuardResponse {
  const token = to.params.hash as string

  const privacyStore = usePrivacyStore()

  try {
    const response = await privacyStore.fetchConsents(token, [ConsentTypesEnum.EMAIL])

    to.meta.consents = response.data
    await fetchDataForCarrierByHash(response.data.carrierHash || '')
    next()
  }
  catch (e: unknown) {
    next({ name: 'all' })
    console.error('Failed to fetch privacy preferences:', e)
  }
}

export async function loggedUserRedirectGuard(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): RouteGuardResponse {
  const authStore = useAuthStore()

  if (isUserLoggedIn()) {
    try {
      await authStore.fetchCurrentUser()
      next({ name: 'request-list' })
    }
    catch (e: unknown) {
      await checkGuardDataError(e as AxiosError, next, 'request-list')
    }
  }
  else {
    next()
  }
}

export async function switchWorkspaceGuard(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): RouteGuardResponse {
  const workspaceId = to.params['workspaceId']
  if (!workspaceId) {
    next()

    return
  }

  try {
    await switchWorkspaceHelper(+workspaceId)
    next(to.fullPath.replace(`/w/${workspaceId}`, ''))
  }
  catch (e) {
    next({ name: 'error' })
  }
}

async function checkGuardDataError(error: AxiosError, next: NavigationGuardNext, redirectName?: string, unauthorizedRedirectName?: string) {
  if (error.response?.status === 401) {
    try {
      await refreshTokenHelper()
      await fetchData()
      redirectName ? next({ name: redirectName }) : next()
    }
    catch {
      // if there is error during token refresh we should redirect to login or register page based on user navigation
      clearAuthData()
      unauthorizedRedirectName ? next({ name: unauthorizedRedirectName }) : next()
    }
  }
  else {
    next({ name: 'error' })
  }
}

async function fetchData() {
  const authStore = useAuthStore()
  if (!authStore.currentUser)
    await authStore.fetchCurrentUser()
}

async function fetchDataForCarrierByHash(hash: string) {
  const carrierStore = useCarriersStore()
  if (!carrierStore.currentCarrierByHash)
    await carrierStore.fetchCurrentCarrierByHash(hash)
}
