/* eslint-disable import/order */
import App from '@/App.vue'
import i18n from '@/plugins/i18n'
import { ToastPlugin } from '@/plugins/toast'
import router from '@/router'
import '@styles/styles.scss'
import 'vue-final-modal/style.css'
import 'bootstrap'
import { createPinia } from 'pinia'
import { createApp } from 'vue'
import { createVfm } from 'vue-final-modal'
import { createHead } from '@unhead/vue'
import { createManager } from '@vue-youtube/core'
import sentryConfig from '@/config/sentry'
import * as Sentry from '@sentry/vue'
import gtmConfig from '@/config/gtm'
import { createGtm } from '@gtm-support/vue-gtm'
import { tooltip } from '@/directives/tooltip'
import { popover } from '@/directives/popover'
import { VueRecaptchaPlugin } from 'vue-recaptcha'
import recaptchaConfig from '@/config/recaptcha'
import { sanitizedHtml } from '@/directives/sanitized-html'

// Create vue app
const app = createApp(App)
const head = createHead()

Sentry.init({
  app,
  ...sentryConfig,
})

// Use plugins
app.use(head)
app.use(createPinia())
app.use(router)
app.use(i18n)
app.use(createVfm())
app.use(createGtm(gtmConfig))
app.use(ToastPlugin)
app.use(VueRecaptchaPlugin, recaptchaConfig)
app.use(createManager({
  deferLoading: {
    enabled: true,
    autoLoad: true,
  },
}))

app.directive('tooltip', tooltip)
app.directive('popover', popover)
app.directive('sanitizedHtml', sanitizedHtml)

app.mount('#app')
