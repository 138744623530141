import { Popover } from 'bootstrap'

export const popover = {

  mounted(el: HTMLElement) {
    el.setAttribute('data-bs-toggle', 'popover')

    createPopover(el)
  },

  beforeUnmount(el: HTMLElement) {
    destroyPopover(el)
  },

  onUnmounted(el: HTMLElement) {
    destroyPopover(el)
  },

}

function createPopover(el: HTMLElement) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars,unused-imports/no-unused-vars
  const bootstrapPopover = new Popover(el, { trigger: 'hover focus' })
}

function destroyPopover(el: HTMLElement) {
  const bootstrapPopover = Popover.getInstance(el)

  bootstrapPopover?.dispose()
}
