import { acceptHMRUpdate, defineStore } from 'pinia'
import api from '@api'
import type { ConsentTypesEnum, PrivacyConsentsCreatePayload, PrivacyConsentsSettingsCreatePayload } from '@apiTypes'

export const usePrivacyStore = defineStore('privacy', {
  actions: {
    fetchConsents(token: string, consentTypes: [ConsentTypesEnum]) {
      return api.privacy.privacyConsentsRetrieve({ token, consentTypes }, { skipAuthToken: true })
    },
    setConsents(hash: string, payload: PrivacyConsentsCreatePayload) {
      return api.privacy.privacyConsentsCreate(hash, payload, { skipAuthToken: true })
    },
    fetchConsentsSettings(token: string) {
      return api.privacy.privacyConsentsSettingsList(token, { skipAuthToken: true })
    },
    setConsentsSettings(hash: string, payload: PrivacyConsentsSettingsCreatePayload) {
      return api.privacy.privacyConsentsSettingsCreate(hash, payload, { skipAuthToken: true })
    },
  },
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(usePrivacyStore, import.meta.hot))
