import type { Member, User, UserDetails, UserGeneralInfo, Workspace } from '@apiTypes'

export function formatUser(user: User | UserDetails | UserGeneralInfo | undefined | null): string {
  if (user?.firstName || user?.lastName)
    return [user?.firstName, user?.lastName].filter(val => !!val).join(' ')

  return user?.email || ''
}

export function mapWorkspaceOnMembers(workspace: Workspace, members: Member[]): Member[] {
  return (members || []).map(member => mapWorkspaceOnMember(workspace, member))
}

function mapWorkspaceOnMember(workspace: Workspace, member: Member): Member {
  if (workspace.id === member.workspace.id)
    return { ...member, workspace: { ...member.workspace, name: workspace.name } }

  return member
}
